import React, { Suspense, Fragment } from 'react';
import {
  string, shape, bool, node
} from 'prop-types';

import { Loading } from '@jotforminc/loading';

import { getAssetComponent } from '../utils/campaign';

import { AssetManagerProvider } from '../context/AssetManagerContext';

const GrowthAssetRenderer = ({
  assetType,
  assetProps,
  campaignInfo,
  Fallback
}) => {
  const orderedCampaignInfos = Object.keys(campaignInfo).includes('type')
    ? [campaignInfo]
    : [
      campaignInfo.promotion,
      campaignInfo.campaign,
      campaignInfo.announcement,
      campaignInfo.launch
    ].filter(f => !!f);

  const [AssetComponent, assetInfo] = orderedCampaignInfos
    .map(info => {
      const {
        status = true,
        type: campaignType,
        assetsVersion,
        assetsAvailable = true,
        assetYear = '2024'
      } = info;

      const Component = getAssetComponent(
        {
          assetInfo: {
            campaignType,
            assetsVersion,
            assetYear
          },
          assetType
        }
      );

      return !Component || !assetsAvailable || !status ? [] : [Component, info];
    })
    .filter(f => !!f.length)[0] || [];

  if (!AssetComponent) {
    return <Fallback {...assetProps} />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <AssetManagerProvider
        campaignInfo={campaignInfo}
        assetInfo={assetInfo}
        assetType={assetType}
      >
        <AssetComponent {...assetProps} />
      </AssetManagerProvider>
    </Suspense>
  );
};

GrowthAssetRenderer.propTypes = {
  assetType: string,
  assetProps: shape({}),
  campaignInfo: shape({
    status: bool,
    type: string,
    assetsVersion: string,
    assetsAvailable: bool,
    assetYear: string
  }),
  Fallback: node
};

GrowthAssetRenderer.defaultProps = {
  assetType: '',
  assetProps: {},
  campaignInfo: {},
  Fallback: Fragment
};

export default GrowthAssetRenderer;
