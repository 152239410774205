import React, { Fragment } from 'react';
import { shape, string, node } from 'prop-types';

import GrowthAssetRenderer from './GrowthAssetRenderer';

const GrowthAssetManager = ({
  assetProps,
  assetType,
  Fallback
}) => {
  return (
    <GrowthAssetRenderer
      campaignInfo={global.window?.campaignInfo}
      assetProps={assetProps}
      assetType={assetType}
      Fallback={Fallback}
    />
  );
};

GrowthAssetManager.propTypes = {
  assetProps: shape({}),
  assetType: string.isRequired,
  Fallback: node
};

GrowthAssetManager.defaultProps = {
  assetProps: {},
  Fallback: Fragment
};

export default GrowthAssetManager;
