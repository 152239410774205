/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { t } from '@jotforminc/translation';
import { func, shape, bool } from 'prop-types';
import React, { useState } from 'react';
import { GrowthAssetManager } from '@jotforminc/growth-asset-manager';
import { getUrlParameter } from '@jotforminc/utils';

import isEmpty from 'lodash/isEmpty';
import { Texts } from '../../constants';
import { getCampaignName } from '../../utils';
import { SaveBagde } from './SaveBagde';

const UpgradeButton = ({
  campaign, onClick, isUKNewUserCampaignActive, user
}) => {
  const {
    type: campaignType, industryCampaign, industryCampaignAssetVersion, assetsVersion
  } = campaign;
  const [campaignInfo] = useState(window?.campaignInfo || {});
  const enableSilverOne = getUrlParameter('silverone') === '1';
  const enableChurch = industryCampaign === 'CHURCH' && industryCampaignAssetVersion === 'v2';
  const enableAnimalShelter = industryCampaign === 'ANIMAL-SHELTER' && industryCampaignAssetVersion === 'v2';
  const enableEducationCampaign = industryCampaign === 'EDUCATION' && industryCampaignAssetVersion === 'v2';
  const enableSpringSale = campaignType === 'REGULARFIFTYDISCOUNT' && assetsVersion === 'v2';
  return (
    <>
      <li className="jNewHeader-accountLinkListItem upgradeItem">
        {!isEmpty(campaignInfo) && getUrlParameter('gamEnabled') === '1' ? (
          <GrowthAssetManager assetType="account-box" assetProps={{ user }} />
        ) : campaignType === 'BLACKFRIDAY' ? (
          <button
            type="button"
            data-testid="upgradeButton"
            className='blackfriday-2023'
            onClick={onClick}
          >
            <div className="blackfriday-2023-label">
              <img src="https://cdn.jotfor.ms/assets/img/campaigns/2023/blackfriday/account-box/label.svg" alt="" />
            </div>
            <div className="blackfriday-2023-title-wrapper">
              <div className="blackfriday-2023-top-title">{t('Limited time offer!')}</div>
              <div className="blackfriday-2023-bottom-title">{t('Black Friday')}</div>
            </div>
            <div className="blackfriday-2023-button">{t('SAVE 50%')}</div>
          </button>
        ) : campaignType === 'EOY' ? (
          <div className="eoy-2023-upgrade-button-wrapper" onKeyDown={onClick} onClick={onClick}>
            <div className="eoy-2023-upgrade-button-wrapper-title">
              {t('END OF YEAR')}
            </div>
            <div className='eoy-2023-upgrade-button-wrapper-badge'>
              {t('SAVE 50%')}
            </div>
          </div>
        )
          : enableSilverOne ? (
            <div className="silver-one-wrapper" onKeyDown={onClick} onClick={onClick}>
              <div className="silver-one-image">
                <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/account-box/coin-2x.png" alt="Coin" />
              </div>
              <div className="silver-one-title-wrapper">
                <div className="silver-one-title">
                  {t('Silver Plan')}
                </div>
                <div className="silver-one-title-2">
                  {t('Only $1')}
                </div>
              </div>
              <button type='button' className='silver-one-button'>Upgrade now</button>
            </div>
          )
            : enableChurch ? (
              <div className="church-wrapper" onKeyDown={onClick} onClick={onClick}>
                <div className="church-wrapper-title">
                  {t('Upgrade Your Plan')}
                </div>
                <div className='church-wrapper-badge'>
                  {t('SAVE 50%')}
                </div>
              </div>
            )
              : enableEducationCampaign ? (
                <button
                  type='button' className="education-campaign-wrapper" onKeyDown={onClick}
                  onClick={onClick}
                >
                  <div className="education-campaign-title-wrapper">
                    <div className="education-campaign-title">
                      {t('Upgrade Your Plan')}
                    </div>
                    <div className='education-campaign-badge'>
                      {t('SAVE 50%')}
                    </div>
                  </div>
                </button>
              )
                : enableSpringSale ? (
                  <div className="spring-sale-wrapper" onKeyDown={onClick} onClick={onClick}>
                    <div className="spring-sale-wrapper-image">
                      <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/spring-sale/account-box/flowers.svg" alt="Flowers" />
                    </div>
                    <div className='spring-sale-title-wrapper'>
                      <div className="spring-sale-wrapper-title">
                        {t('SUMMER SALE')}
                      </div>
                      <div className='spring-sale-wrapper-badge'>
                        {t('SAVE 50%')}
                      </div>
                    </div>
                  </div>
                )
                  : enableAnimalShelter ? (
                    <div className="animal-shelter-wrapper" onKeyDown={onClick} onClick={onClick}>
                      <div className="animal-shelter-wrapper-title">
                        {t('Upgrade Your Plan')}
                      </div>
                      <div className='animal-shelter-wrapper-badge'>
                        {t('SAVE 50%')}
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      data-testid="upgradeButton"
                      className={`locale jNewHeader-accountLinkListItemLink ${campaignType === 'SUMMER' ? 'jf-summer-sale-button-upgrade-button' : 'upgradeBubble'} ${isUKNewUserCampaignActive || campaignType === 'BACKTOPAID' ? 'upgradeBubble--ukNewUserCampaignActive' : ''} ${getCampaignName(campaign)} upgradeBtnClass`}
                      onClick={onClick}
                    >
                      {getCampaignName(campaign) === 'eoy2022' ? <span>{t(Texts.UPGRADE_YOUR_PLAN)}</span> : t(Texts.UPGRADE_YOUR_PLAN)}

                      {isUKNewUserCampaignActive || campaignType === 'BACKTOPAID' ? (
                        <div className="ukNewUserCampaignActive">
                          <SaveBagde
                            campaign={campaign} isUKNewUserCampaignActive={isUKNewUserCampaignActive} isOverquotaCampaignActive={campaignType === 'OVERQUOTAUSER'}
                            isBtp2023CampaignActive={campaignType === 'BACKTOPAID'}
                          />
                        </div>
                      ) : (
                        <SaveBagde
                          campaign={campaign} isUKNewUserCampaignActive={isUKNewUserCampaignActive} isOverquotaCampaignActive={campaignType === 'OVERQUOTAUSER'}
                          isBtp2023CampaignActive={campaignType === 'BACKTOPAID'}
                        />
                      )}
                    </button>
                  )}

      </li>
    </>
  );
};

UpgradeButton.propTypes = {
  campaign: shape({}).isRequired,
  onClick: func.isRequired,
  isUKNewUserCampaignActive: bool,
  user: shape({}).isRequired
};
UpgradeButton.defaultProps = {
  isUKNewUserCampaignActive: false
};

export default UpgradeButton;
